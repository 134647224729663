import React from 'react';
import { Box, makeStyles, Paper, Typography } from '@material-ui/core';
import { ErrorOutline as ErrorOutlineIcon } from '@material-ui/icons';
import * as Sentry from '@sentry/react';
import { Page } from 'components/page';
import logo from 'images/logo.png';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {
    textAlign: 'center',
    padding: theme.spacing(6),
    marginTop: 'auto',
  },
  icon: {
    fontSize: 50,
    marginBottom: theme.spacing(2),
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 'auto',
  },
}));

export const ErrorBoundary: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const classes = useStyles();

  return (
    <Sentry.ErrorBoundary
      fallback={() => (
        <Page maxWidth="sm" showHeader={false} className={classes.root}>
          <Paper className={classes.paper} elevation={2}>
            <Box flex={1} display="flex" flexDirection="column" alignItems="center">
              <ErrorOutlineIcon color="error" className={classes.icon} />
              <Typography>Une erreur est survenue, veuillez réessayer plus tard.</Typography>
            </Box>
          </Paper>

          <Box className={classes.logo}>
            <img src={logo} alt="Logo Gnoing" height={40} />
          </Box>
        </Page>
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
