import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import App from 'containers/app';
import store from 'store';
import theme from 'theme';
import { setupAxiosInterceptors } from 'utils/api.utils';
import { ErrorBoundary, Loader } from './components';
import './index.css';

Sentry.init({
  dsn: 'https://79f1e5c5599679843f8f5ce6fdf982bd@o465436.ingest.sentry.io/4506638219476992',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session <Replay></Replay>
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const persistor = persistStore(store);

setupAxiosInterceptors(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<Loader />} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <Router>
            <App />
          </Router>
        </ErrorBoundary>
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.querySelector('#root'),
);
